import ms from 'ms'

const formatTravelTime = duration => {
  const durationHours = Math.floor(duration)
  const durationMins = duration % 60

  const hoursMessage =
    durationHours < 60 ? null : ms(durationHours * 60000, { long: true })
  const minsMessage =
    durationMins === 0 ? null : ms(durationMins * 60000, { long: true })

  return hoursMessage && minsMessage
    ? `${hoursMessage} and ${minsMessage}`
    : hoursMessage
    ? hoursMessage
    : minsMessage
}

export { formatTravelTime }
