import React, { useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Skeleton,
  Snackbar,
  Alert,
  Button
} from '@mui/material'
import { FaStream } from 'react-icons/fa'
import { Drawer } from './Drawer'
import { Search } from '../Search'
import {
  useFlightNumberSuggestions,
  useLoading,
  useNavigation,
  useWSMessageListener,
  useWSSender
} from '../../hooks'
import { LayoutContainer } from '../LayoutContainer'
import { colors } from '../../styles/colors'

const FLIGHT_ID_ERROR_MESSAGE =
  'Flight ID not recognised. Please try another one'
const CORRUPT_DATA_ERROR_MESSAGE =
  'Invalid data received. Please try another flight ID'

const Header = () => {
  const [open, setOpen] = useState(false)
  const [errorAlertOpen, setErrorAlertOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const [searchError, setSearchError] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')

  const { loadingDone, readyToLoad, startLoading, resetLoading } = useLoading()
  const { resetNav } = useNavigation()
  const { send } = useWSSender()

  const flightNumberSuggestions = useFlightNumberSuggestions()


  const toggleDrawer = state => event => {
    setOpen(state)
  }

  useWSMessageListener(data => {
    if (data.type === 'flight-status') {

      if (data.errorType) {
        resetLoading()
      }

      if (data.errorType === 'not-found') {
        setErrorMessage(FLIGHT_ID_ERROR_MESSAGE)
      } else if (data.errorType === 'invalid-data') {
        setErrorMessage(CORRUPT_DATA_ERROR_MESSAGE)
      }
      const flightStatus = JSON.parse(data.data)
      if (!flightStatus) {
        setErrorAlertOpen(true)
      }
    }
  })

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setErrorAlertOpen(false)
  }

  const validateSearch = value => {
    const flightNumberPattern = /^[A-Za-z]{1,3}\d{1,5}$/
    if (!flightNumberPattern.test(value)) {
      setSearchError(true)
    } else {
      setSearchError(false)
    }
  }

  const onChangeSearch = (value) => {
    setSearchValue(value)
    validateSearch(value)
  }

  const handleSearchSubmit = () => {
    if (!searchError && searchValue !== '') {
      resetNav()
      send({ action: 'initial-load', data: searchValue })
      startLoading()
    }
  }

  return (
    <>
      <Box sx={{ width: '100%', backgroundColor: colors.gray[200] }}>
        <Box sx={styles.container}>
          <Box sx={{ width: '550px' }}>
            <Search
              value={searchValue}
              error={searchError}
              isLoading={!readyToLoad && !loadingDone}
              onChange={onChangeSearch}
              onSubmit={handleSearchSubmit}
              placeholder="Enter a Flight number"
            />
          </Box>
          <IconButton
            onClick={toggleDrawer(true)}
            aria-label="menu"
            sx={{
              ...styles.toogleDrawerIcon,
              backgroundColor: colors.gray[300],
              color: colors.blue[800]
            }}
          >
            <FaStream size={12} />
          </IconButton>
        </Box>
      </Box>
      <LayoutContainer>
        <Box sx={styles.flightIdsExample}>
          <Typography marginRight=".5rem">Try this:</Typography>
          <Box height={30}>
            {flightNumberSuggestions.length >= 3 ? (
              <Box display="flex" gap=".5rem">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSearchValue(flightNumberSuggestions[0])
                  }}
                >
                  {flightNumberSuggestions[0]}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSearchValue(flightNumberSuggestions[1])
                  }}
                >
                  {flightNumberSuggestions[1]}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSearchValue(flightNumberSuggestions[2])
                  }}
                >
                  {flightNumberSuggestions[2]}
                </Button>
              </Box>
            ) : (
              <Skeleton variant="rectangular" width={350} height={22} />
            )}
          </Box>
        </Box>
      </LayoutContainer>
      <Snackbar
        open={errorAlertOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Drawer open={open} toggleDrawer={toggleDrawer} />
    </>
  )
}

const styles = {
  header: {
    backgroundColor: '#130048',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '@media (max-width:600px)': {
      maxWidth: 'sm',
      width: '100%'
    }
  },
  title: {
    cursor: 'pointer',
    fontFamily: 'Montserrat',
    color: 'white',
    fontSize: '2rem',
    marginRight: '1rem',
    '@media (max-width:600px)': {
      fontSize: '1.5rem'
    }
  },
  toogleDrawerIcon: {
    padding: '1rem',
    backgroundColor: colors.gray[300],
    color: colors.blue[800],
    position: 'absolute',
    right: 10,
    top: 22,
    '@media (max-width:600px)': {
      position: 'relative',
      right: 0,
      top: 0,
      mr: '1rem',
      ml: '1rem'
    }
  },
  flightIdsExample: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    marginTop: '1rem',
    '@media (max-width:600px)': {
      px: '1rem'
    }
  }
}

export { Header }
