import React, { useState, useEffect } from 'react'
import { Box, IconButton, Typography, Drawer as MUIDrawer } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useWSMessageListener, useLoading } from '../../hooks'
import { DRAWER_WIDTH } from './constants'
import { formatDate } from '../../utils'
import { Tag } from './Tag'

const Drawer = ({ open, toggleDrawer }) => {
  const [adminInfo, setAdminInfo] = useState(null)

  const { loadingState } = useLoading()

  useEffect(() => {
    if (loadingState['flight-status']) {
      setAdminInfo(null)
    }
  }, [loadingState])

  useWSMessageListener(data => {
    if (data.type === 'console') {
      if (data.data.includes('adminInfoData')) {
        const { adminInfoData } = JSON.parse(data.data)
        setAdminInfo(value => ({ ...value, ...adminInfoData }))
      }
      if (data.data.includes('interests')) {
        const { interests } = JSON.parse(data.data)
        setAdminInfo(value => ({ ...value, interests }))
      }
      if (data.data.includes('openChatQuestionKeywords')) {
        const { openChatQuestionKeywords } = JSON.parse(
          data.data.replace('\n', '')
        )

        setAdminInfo(value => ({
          ...value,
          openChatQuestionKeywords
        }))
      }
    }
  })

  return (
    <MUIDrawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          border: 'none',
          backgroundColor: '#111',
          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'
        }
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Box
        sx={{ width: DRAWER_WIDTH, height: '100vh' }}
        role="presentation"
        backgroundColor="#262A2F"
        p="1.75rem"
      >
        <IconButton
          onClick={toggleDrawer(false)}
          color="white"
          aria-label="close drawer"
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            background: '#1C1E20'
          }}
        >
          <CloseIcon htmlColor="#fff" />
        </IconButton>
        <Typography
          sx={{
            fontSize: '1.15rem',
            fontWeight: '700',
            color: '#ffffff'
          }}
        >
          User Data collected
        </Typography>
        {adminInfo && (
          <Box
            sx={{
              mt: '2rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.65rem'
            }}
          >
            <Box sx={styles.infoContainer}>
              <Typography sx={styles.label}>FlightID</Typography>
              <Tag>{adminInfo.flightId}</Tag>
            </Box>
            <Box sx={styles.infoContainer}>
              <Typography sx={styles.label}>Departure</Typography>
              <Box sx={styles.infoList}>
                <Tag>{adminInfo.departureCity}</Tag>
                {adminInfo.departureActualTimeLocal && (
                  <Tag>{formatDate(adminInfo.departureActualTimeLocal)}</Tag>
                )}
                <Tag>{adminInfo.weather}</Tag>
              </Box>
            </Box>
            <Box sx={styles.infoContainer}>
              <Typography sx={styles.label}>Destination</Typography>
              <Box sx={styles.infoList}>
                <Tag>{adminInfo.arrivalCity}</Tag>
                {adminInfo.arrivalActualTimeLocal && (
                  <Tag>{formatDate(adminInfo.arrivalActualTimeLocal)}</Tag>
                )}
                <Tag>{adminInfo.weather}</Tag>
              </Box>
            </Box>
            <Box sx={styles.infoContainer}>
              <Typography sx={styles.label}>Interest(s)</Typography>
              <Box sx={styles.infoList}>
                {adminInfo.interests &&
                  adminInfo.interests.map(item => <Tag key={item}>{item}</Tag>)}
              </Box>
            </Box>
            {adminInfo.openChatQuestionKeywords && (
              <Box sx={styles.infoContainer}>
                <Typography sx={styles.label}>
                  Recommendation keyword(s)
                </Typography>
                <Box sx={styles.infoList}>
                  {adminInfo.openChatQuestionKeywords.map(item => (
                    <Tag key={item}>{item}</Tag>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </MUIDrawer>
  )
}

const styles = {
  label: {
    fontSize: '1rem',
    fontWeight: '700',
    color: '#ffffff'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content'
  },
  infoList: {
    display: 'flex',
    gap: '.75rem',
    flexWrap: 'wrap'
  }
}

export { Drawer }
