import { Skeleton } from './Skeleton'
import { Box, Card as MUICard, Typography } from '@mui/material'

const Card = ({ children, title, isLoading, id }) => {
  return (
    <Box as="section" sx={styles.section}>
      <Typography variant="h3" id={id}>
        {title}
      </Typography>
      <MUICard>
        {isLoading ? (
          <Box sx={styles.skeletonContainer}>
            <Skeleton />
          </Box>
        ) : (
          children
        )}
      </MUICard>
    </Box>
  )
}

const styles = {
  skeletonContainer: {
    padding: '1.625rem'
  },
  section: {
    '& + section': {
      marginTop: '2.5rem'
    }
  }
}

export { Card }
