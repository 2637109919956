import { Box, Typography } from '@mui/material'
import { ItemList, Item } from './Items'
import { colors } from '../../styles/colors'
import { useNavigation } from '../../hooks'

const Navigation = () => {
  const { navigationItems, isNavEmpty } = useNavigation()

  if (isNavEmpty) {
    return null
  }

  return (
    <Box position="absolute" top="0" left="0" height="100%" width="20%">
      <Box
        position="sticky"
        top="0"
        left="0"
        p="1rem"
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Typography color={colors.blue[800]} fontSize="0.85rem" pb="0.5rem">
          Navigate to:
        </Typography>
        <ItemList>
          {Object.entries(navigationItems).map(([id, item]) =>
            item.isVisible ? <Item key={id} id={id} label={item.label} /> : null
          )}
        </ItemList>
      </Box>
    </Box>
  )
}

export { Navigation }
