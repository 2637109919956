import { Box, Typography } from '@mui/material'
import React from 'react'

import { QuestionButton } from './QuestionButton'

export const TRAVEL_TYPES = {
  BUSINESS: 'business',
  TRAINING: 'training',
  FRIEND: 'friend',
  CITY: 'city',
  OUTDOOR: 'outdoor',
  SOMETHING_ELSE: 'somethingElse',
  UNSPECIFIED: 'unspecified'
}

const items = [
  { type: TRAVEL_TYPES.BUSINESS, label: 'Meet people for business reason' },
  { type: TRAVEL_TYPES.TRAINING, label: 'Attend a conference or training' },
  { type: TRAVEL_TYPES.FRIEND, label: 'Visit friends and/or family' },
  { type: TRAVEL_TYPES.CITY, label: 'Explore the city' },
  { type: TRAVEL_TYPES.OUTDOOR, label: 'Enjoy some outdoor activities' },
  { type: TRAVEL_TYPES.SOMETHING_ELSE, label: 'Something else' },
  { type: TRAVEL_TYPES.UNSPECIFIED, label: 'Prefer not to say' }
]

const TravelTypeSelector = ({ onSelection, id = null, loadingType = null }) => {
  return (
    <Box as="section">
      <Typography variant="h3" id={id}>
        Do you want to share the nature for your travel?
      </Typography>
      <Box sx={{ px: '1.65rem' }}>
        {items.map(item => (
          <QuestionButton
            key={item.type}
            isLoading={loadingType === item.type}
            onClick={() => onSelection(item.type)}
          >
            {item.label}
          </QuestionButton>
        ))}
      </Box>
    </Box>
  )
}

export { TravelTypeSelector }
