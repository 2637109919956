import React, { useEffect } from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
  Marker
} from '@react-google-maps/api'

import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../../constants'

const containerStyle = {
  width: '400px',
  height: '400px'
}

export function Map({ location, destination, setDestinationDirectionData }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = React.useState(null)
  const [directionsResponse, setDirectionsResponse] = React.useState(null)

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const calculateRoute = React.useCallback(() => {
    const currentLocation = new window.google.maps.LatLng(
      location.lat,
      location.lng
    )

    const destinationLocation = new window.google.maps.LatLng(
      destination?.location?.lat,
      destination?.location?.lon
    )
    const google = window.google
    const directionsService = new google.maps.DirectionsService()
    const directionsRequest = {
      origin: currentLocation,
      destination: destinationLocation,
      travelMode: google.maps.TravelMode.DRIVING
    }

    directionsService.route(directionsRequest, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        const fastestRoute = getFastestRoute(result.routes)
        setDirectionsResponse(result)
        setDestinationDirectionData({
          fastestRoute: fastestRoute.summary,
          duration: (fastestRoute.legs[0].duration.value / 60).toFixed(), // in minutes
          includeFerry: checkFerry(fastestRoute)
        })
      } else {
        console.error('error fetching directions', result, status)
      }
    })
  }, [
    destination?.location?.lat,
    destination?.location?.lon,
    location,
    setDestinationDirectionData
  ])

  useEffect(() => {
    if (destination?.location?.lat && destination?.location?.lon && map) {
      setDirectionsResponse(null)
      calculateRoute()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    destination?.location?.lat,
    destination?.location?.lon
  ])

  const onLoad = React.useCallback(
    function callback(map) {
      if (!destination?.location) {
        setMap(map)
        return
      }

      calculateRoute()
      setMap(map)
    },
    [destination, calculateRoute]
  )

  if (!REACT_APP_GOOGLE_MAPS_API_KEY) {
    console.error('Google API key with error.')
    return null
  }

  if (!isLoaded) {
    return null
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={location}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {directionsResponse ? (
        <DirectionsRenderer directions={directionsResponse} />
      ) : (
        <>
          {/* If there is not directions, center the map on current location */}
          <Marker position={{ lat: location.lat, lng: location.lng }} />
        </>
      )}
    </GoogleMap>
  )
}

function getFastestRoute(routes) {
  if (routes.length === 0) return
  return routes.reduce(function (prev, curr) {
    return prev.duration.value < curr.duration.value ? prev : curr
  })
}

function checkFerry(route) {
  const mergedSteps = route.legs.map(leg => leg.steps).flat()
  return mergedSteps.find(step => step.maneuver === 'ferry')
}
