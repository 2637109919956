import { Box, Typography } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import {
  useLoading,
  useWSMessageListener,
  useWSSender,
  useNavigation,
  NAVIGATION_IDS
} from '../../hooks'
import { TravelTypeSelector } from '../TravelTypeSelector'
import { QuestionButton } from '../QuestionButton'

function splitQuestions(questions) {
  const questionArr = questions.split('\n').filter(Boolean)
  const firstQuestion = questionArr.shift()
  return { firstQuestion, questions: questionArr }
}

const Questions = ({
  travelTipQuestion,
  setTravelTipQuestion,
  resetTravelTip,
  travelTip,
  travelType,
  setTravelType,
  destinationFeature
}) => {
  const [questionSelected, setQuestionSelected] = useState(null)
  const [refreshIsLoading, setRefreshIsLoading] = useState(null)
  const [city, setCity] = useState('')
  const [openChatQuestions, setOpenChatQuestions] = useState(null)
  const [nextQuestion, setNextQuestion] = useState(null)
  const [recomendations, setRecomendations] = useState([])
  const [loadingAnswer, setLoadingAnswer] = useState(false)
  const { loadingState, loadingSectionDone, startLoadingSection } = useLoading()
  const { showNavItem } = useNavigation()

  const isLoading = loadingState['open-chat-questions']
  const isDataLoaded = useMemo(
    () => openChatQuestions && !isLoading,
    [isLoading, openChatQuestions]
  )

  useEffect(() => {
    if (loadingState['flight-status']) {
      setOpenChatQuestions(null)
      setLoadingAnswer(false)
      setTravelType(null)
      setRecomendations([])
    }
  }, [loadingState, setTravelType])

  useEffect(() => {
    if (isDataLoaded && recomendations.length === 0) {
      showNavItem(NAVIGATION_IDS.ACTIVITIES, 'More infos...')
    }
  }, [isDataLoaded, recomendations, showNavItem])

  function checkNextQuestion(currentQuestions, newQuestions) {
    const uniqueQuestions = newQuestions
      .filter(question => !currentQuestions.includes(question))
      .filter(question => question !== '')

    if (uniqueQuestions.length > 0) {
      return uniqueQuestions[0]
    }

    return null
  }

  useWSMessageListener(data => {
    if (data.type === 'flight-status') {
      setOpenChatQuestions(null)
    }
    if (data.type === 'open-chat') {
      setLoadingAnswer(false)

      const filterQuestionsWithoutSelected = questions =>
        questions.filter(question => question !== questionSelected)

      if (nextQuestion) {
        setOpenChatQuestions(questions => [
          ...filterQuestionsWithoutSelected(questions),
          nextQuestion
        ])
      } else {
        setOpenChatQuestions(questions =>
          filterQuestionsWithoutSelected(questions)
        )
      }

      setQuestionSelected(null)
      setNextQuestion(null)
      setRecomendations(currentAnswers => [...currentAnswers, data.data])
    }
    if (data.type === 'open-chat-questions') {
      setRefreshIsLoading(false)
      loadingSectionDone('open-chat-questions')
      const { firstQuestion, questions } = splitQuestions(
        data.data.openChatQuestions
      )
      if (!travelTipQuestion && city) {
        setTravelTipQuestion(firstQuestion)
        send({
          action: 'travel-tips',
          data: { question: firstQuestion, city }
        })
      }

      if (!openChatQuestions || refreshIsLoading) {
        setOpenChatQuestions(questions)
      } else {
        const nextQuestion = checkNextQuestion(openChatQuestions, questions)

        if (nextQuestion) {
          setNextQuestion(nextQuestion)
        }
      }
      setCity(data.data.city)
    }
  })

  useEffect(() => {
    resetTravelTip()
  }, [isLoading, resetTravelTip])

  const { send } = useWSSender()

  const quickAnswers = async question => {
    setQuestionSelected(question)
    send({ action: 'open-chat', data: { question, city } }, () => {
      setLoadingAnswer(true)
    })

    send({ action: 'open-chat-questions', data: { city, travelType } })
  }

  const refreshQuestions = () => {
    send({
      action: 'open-chat-questions',
      data: { city, travelType, questionToAvoid: travelTipQuestion }
    })
    setOpenChatQuestions(null)
    setRefreshIsLoading(true)
  }

  const loadQuestions = travelType => {
    if (!travelTip && travelTipQuestion) {
      setTravelTipQuestion('')
    }
    send({
      action: 'open-chat-questions',
      data: { city, travelType, questionToAvoid: null }
    })
    startLoadingSection('open-chat-questions')
    setTravelType(travelType)
    setOpenChatQuestions(null)
  }

  const noTravelTypeSelected =
    loadingState['flight-status'] === false && travelType === null
  const areQuestionsLoading =
    loadingState['open-chat-questions'] === true && travelType !== null
  if (noTravelTypeSelected || areQuestionsLoading) {
    return (
      <TravelTypeSelector
        id={NAVIGATION_IDS.ACTIVITIES}
        onSelection={loadQuestions}
        loadingType={
          loadingState['open-chat-questions'] && travelType ? travelType : null
        }
      />
    )
  }

  if (loadingState['open-chat-questions'] === null || !openChatQuestions) {
    return null
  }

  return (
    <>
      <Box as="section">
        <Typography
          variant="h3"
          id={recomendations.length ? '' : NAVIGATION_IDS.ACTIVITIES}
        >
          Do you want more recommendations?
        </Typography>
        <Box sx={{ px: '1.65rem' }}>
          {!destinationFeature.get().hideOption && (
            <QuestionButton
              isLoading={refreshIsLoading}
              onClick={() => destinationFeature.set({ ...destinationFeature, showMap: true })}
            >
              Need help get yo your final destination
            </QuestionButton>
          )}
          {openChatQuestions?.map((question, index) => (
            <QuestionButton
              key={index}
              isLoading={loadingAnswer && questionSelected === question}
              onClick={() => quickAnswers(question)}
            >
              {question}
            </QuestionButton>
          ))}
          <QuestionButton
            isLoading={refreshIsLoading}
            onClick={refreshQuestions}
          >
            More questions...
          </QuestionButton>
        </Box>
      </Box>
    </>
  )
}

export { Questions }
