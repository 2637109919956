import React, { useState, useCallback } from 'react'
import { Recomendations, Questions, TravelTips } from '../components/OpenChat'
import { LocationSearch } from './LocationSearch'

export function OpenChatSection() {
  const [currentTravelTipQuestion, setCurrentTravelTipQuestion] = useState('')
  const [travelTip, setTravelTip] = useState('')
  const [travelTipTitle, setTravelTipTitle] = useState('')
  const [travelType, setTravelType] = useState(null)
  const [destinationFeature, setDestinationFeature] = useState({ showMap: false, hideOption: false })

  const handleResetTravelTips = useCallback(() => {
    setTravelTip('')
    setTravelTipTitle('')
  }, [setTravelTip, setTravelTipTitle])
  return (
    <>
      {travelType && (
        <TravelTips
          setTravelTip={setTravelTip}
          travelTip={travelTip}
          setTravelTipTitle={setTravelTipTitle}
          travelTipTitle={travelTipTitle}
        />
      )}
      <Recomendations />
      {destinationFeature.showMap && <LocationSearch />}
      <Questions
        destinationFeature={{ set: setDestinationFeature, get: () => destinationFeature}}
        travelType={travelType}
        setTravelType={setTravelType}
        travelTip={travelTip}
        travelTipQuestion={currentTravelTipQuestion}
        setTravelTipQuestion={setCurrentTravelTipQuestion}
        resetTravelTip={handleResetTravelTips}
      />
    </>
  )
}
