import { Box, Typography } from '@mui/material'
import { colors } from '../styles/colors'

export function WeatherInfo({ data }) {
  if (data === null) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        img: {
          pt: '.1rem',
          width: '50px'
        },
        ml: '-.25rem'
      }}
    >
      <img
        src={data.condition.icon}
        alt="weather condition icon"
        loading="lazy"
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ml: '.5rem'
        }}
      >
        <Typography
          sx={{
            color: colors.gray[800],
            fontSize: '.85rem',
            fontWeight: '600'
          }}
        >
          {data.temperature} °C
        </Typography>
        <Box
          sx={{
            mx: '.75rem',
            width: '1px',
            height: '36px',
            borderRight: `1px solid ${colors.gray[400]}`
          }}
        />
        <Typography
          sx={{
            color: colors.gray[600],
            fontSize: '.85rem',
            fontWeight: '400'
          }}
        >
          {data.condition.text}
        </Typography>
      </Box>
    </Box>
  )
}
