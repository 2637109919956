import { createContext, useCallback, useMemo, useState } from 'react'

const NavigationContext = createContext()

const NAVIGATION_IDS = {
  GETTING_TO_AIRPORT: 'gettingToAriport-id',
  AIRPORT_INFO: 'airportInfo-id',
  DEPARTURE: 'departure-id',
  DESTINATION: 'destination-id',
  ACTIVITIES: 'activities-id'
}

const NavigationProvider = ({ children }) => {
  const [navigationItems, setNavigationItems] = useState(
    Object.values(NAVIGATION_IDS).reduce(
      (object, id) => ({
        ...object,
        [id]: {
          label: '',
          isVisible: false
        }
      }),
      {}
    )
  )

  const showNavItem = useCallback((id, label) => {
    setNavigationItems(prevState => {
      const item = prevState[id]
      if (item && (item.label !== label || !item.isVisible)) {
        return {
          ...prevState,
          [id]: {
            label,
            isVisible: true
          }
        }
      }
      return prevState
    })
  }, [])

  const hideNavItem = useCallback(id => {
    setNavigationItems(prevState => {
      const item = prevState[id]
      if (item && item.isVisible) {
        return {
          ...prevState,
          [id]: {
            label: '',
            isVisible: false
          }
        }
      }
      return prevState
    })
  }, [])

  const resetNav = useCallback(() => {
    setNavigationItems(
      Object.values(NAVIGATION_IDS).reduce(
        (object, id) => ({
          ...object,
          [id]: {
            label: '',
            isVisible: false
          }
        }),
        {}
      )
    )
  }, [])

  const isNavEmpty = useMemo(
    () =>
      Object.values(navigationItems).every(item => item.isVisible === false),
    [navigationItems]
  )

  const value = useMemo(
    () => ({
      navigationItems,
      showNavItem,
      hideNavItem,
      resetNav,
      isNavEmpty
    }),
    [hideNavItem, isNavEmpty, navigationItems, resetNav, showNavItem]
  )

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  )
}

export { NavigationContext, NavigationProvider, NAVIGATION_IDS }
