import { useState, useMemo, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { Card } from '../components/Card'
import { colors } from '../styles/colors'
import {
  useLoading,
  useWSMessageListener,
  useNavigation,
  NAVIGATION_IDS
} from '../hooks'
import { formatDate } from '../utils'
import { CardSkeleton } from '../components/CardSkeleton'
import { WeatherInfo } from '../components/WeatherInfo'

const CARD_TITLE = 'Destination'

export function Destination() {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const { loadingState, loadingSectionDone } = useLoading()
  const [weatherData, setWeatherData] = useState(null)
  const { showNavItem } = useNavigation()

  const isLoading = loadingState['flight-status']
  const isDataLoaded = useMemo(() => data && !isLoading, [data, isLoading])

  useEffect(() => {
    if (isDataLoaded) {
      showNavItem(NAVIGATION_IDS.DESTINATION, CARD_TITLE)
    }
  }, [isDataLoaded, showNavItem])

  useWSMessageListener(data => {
    try {
      if (data.type === 'weather-info') {
        const {
          arrival: {
            current: { temp_c: temperature, condition }
          }
        } = data.data
        setWeatherData({
          temperature,
          condition
        })
      }
      if (data.type === 'flight-status') {
        const flightStatus = JSON.parse(data.data)

        if (!flightStatus || flightStatus.error) {
          setData(null)
        } else {
          const {
            airport: { iata, municipalityName },
            scheduledTimeLocal,
            actualTimeLocal
          } = flightStatus.arrival

          setData({
            airportCode: iata,
            cityName: municipalityName,
            actualTimeLocal: actualTimeLocal
              ? formatDate(actualTimeLocal)
              : scheduledTimeLocal
              ? formatDate(scheduledTimeLocal)
              : 'N/A',
            scheduledTimeLocal: scheduledTimeLocal
              ? formatDate(scheduledTimeLocal)
              : 'N/A'
          })
          loadingSectionDone('flight-status')
        }
      }
    } catch (error) {
      setError(error)
    }
  })

  if (loadingState['flight-status'] === null || error) {
    return null
  }

  return (
    <Card title={CARD_TITLE} id={NAVIGATION_IDS.DESTINATION}>
      {isLoading ? <CardSkeleton /> : null}
      {isDataLoaded ? (
        <Box sx={styles.container}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline'
            }}
          >
            <Typography
              sx={{
                color: colors.blue[800],
                fontSize: '.875rem',
                fontWeight: '500'
              }}
            >
              {data.airportCode}
            </Typography>
            <Typography
              sx={{
                color: colors.gray[600],
                fontSize: '.875rem',
                fontWeight: '400'
              }}
            >
              Scheduled {data.scheduledTimeLocal}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between'
            }}
          >
            <Typography
              sx={{
                color: colors.blue[800],
                fontSize: '1.25rem',
                fontWeight: '700'
              }}
            >
              {data.cityName}
            </Typography>
            <Typography
              sx={{
                color: colors.blue[800],
                fontSize: '1rem',
                fontWeight: '400'
              }}
            >
              Actual: {data.actualTimeLocal}
            </Typography>
          </Box>
          <WeatherInfo data={weatherData} />
        </Box>
      ) : null}
    </Card>
  )
}

const styles = {
  container: {
    padding: '1.625rem',
    display: 'flex',
    flexDirection: 'column'
  }
}
