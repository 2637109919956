import { useContext, useState } from 'react'
import { MapRoute } from '../components/Map'
import { FlightDataContext } from '../context/FlightDataContext'
import { useLoading } from '../hooks'
import { Search } from '../components'
import { Box } from '@mui/material'
import { convertAddressToLocation } from '../services/location'

const LOCATION_SEARCH_STATE = { loading: false, error: false, location: null }

const LocationSearch = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [destination, setDestination] = useState(LOCATION_SEARCH_STATE)
  const { error, data: flightData } = useContext(FlightDataContext)
  const { loadingState } = useLoading()

  const updateDestinationState = (key, value) =>
    setDestination({ ...LOCATION_SEARCH_STATE, [key]: value })

  const onSubmitAddressSearch = async () => {
    updateDestinationState('isLoading', true)
    if (searchTerm) {
      try {
        const {
          geometry: { location }
        } = await convertAddressToLocation(searchTerm)
        updateDestinationState('location', {
          lat: location.lat,
          lon: location.lng
        })
      } catch (error) {
        updateDestinationState('error', error)
      }
    }
  }

  if (!flightData || loadingState['flight-status']) {
    return null
  }

  const {
    location: { lat: latitude, lon: longitude }
  } = flightData?.arrival?.airport

  return (
    <MapRoute
      latestLeaveTime={flightData?.latestLeaveTime}
      departureLocation={{
        latitude,
        longitude,
        error,
        loading: loadingState['flight-status']
      }}
      destinationData={destination}
      cardTitle="Final Destination"
      id="final-destination-card"
    >
      <Box paddingBottom={2}>
        <Search
          value={searchTerm}
          error={!!destination.error}
          isLoading={destination.loading}
          onChange={value => setSearchTerm(value)}
          onSubmit={() => onSubmitAddressSearch()}
          placeholder="E.g Tokyo Hotel"
        />
      </Box>
    </MapRoute>
  )
}

export { LocationSearch }
