import { useWSSender } from './useWSSender'
import { useWSMessageListener } from './useWSMessageListener'
import { useWSStatusChangeListener } from './useWSStatusChangeListener'
import { useState } from 'react'

// Removed 'GRU' airport as it returned invalid data from third party API
const airports = ['ATL', 'LHR', 'CDG', 'AMS', 'HND', 'MEX']

const useFlightNumberSuggestions = () => {
  const { send } = useWSSender()
  const [suggestions, setSuggestions] = useState([])

  useWSStatusChangeListener(status => {
    const airport = airports[Math.floor(Math.random() * airports.length)]
    if (status === 'OPEN') {
      send({
        action: 'flight-number-suggestions',
        data: airport
      })
    }
  })

  useWSMessageListener(({ type, data }) => {
    if (type === 'flight-number-suggestions') {
      setSuggestions(JSON.parse(data || '[]'))
    }
  })

  return suggestions
}

export { useFlightNumberSuggestions }
