import { useContext } from 'react'

import { Box, Button, Typography, CircularProgress } from '@mui/material'
import { colors } from '../styles/colors'
import { AuthContext } from '../context'
import { Credits } from '../sections'

export const LogIn = () => {
  const { signInWithGoogle, signInWithMicrosoft, authLoading } =
    useContext(AuthContext)

  if (authLoading === undefined) return
  return (
    <Box
      sx={{
        transition: 'margin 0.2s',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          direction: 'column',
          justifyContent: 'center',
          height: '80vh',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            direction: 'column',
            justifyContent: 'center',
            alignContent: '',
            alignItems: 'center',
            maxWidth: '30%'
          }}
        >
          <Typography sx={styles.title}>Experience</Typography>
          <Typography sx={styles.title}>the Future of Travel ✈️</Typography>
          <Typography
            sx={{
              color: colors.blue[800],
              fontSize: '14px',
              textAlign: 'start',
              padding: 1
            }}
          >
            The experience requires authentication of a Google or Microsoft
            account. This is used for security reasons, i.e. to avoid spam.
            Alternatively, you can contact us via the NearForm website and we
            can organise a demo for you.
          </Typography>
          <Button
            onClick={signInWithGoogle}
            disabled={authLoading}
            sx={styles.buttonGoogle}
          >
            {authLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Sign in with Google'
            )}
          </Button>
          <Typography
            sx={{
              color: colors.blue[800],
              fontWeight: 'bold',
              fontSize: '14px',
              textAlign: 'center',
              padding: 1
            }}
          >
            or
          </Typography>
          <Button
            onClick={signInWithMicrosoft}
            disabled={authLoading}
            sx={styles.buttonMicrosoft}
          >
            {authLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Sign in with Microsoft'
            )}
          </Button>
        </Box>
      </Box>
      <Credits />
    </Box>
  )
}
const styles = {
  buttonGoogle: {
    width: '100%',
    marginTop: '20px',
    '&': {
      backgroundColor: colors.blue[800],
      color: 'white'
    },
    '&:hover': {
      backgroundColor: colors.blue[800],
      opacity: '.8',
      color: 'white'
    }
  },
  buttonMicrosoft: {
    width: '100%',
    '&': {
      backgroundColor: colors.blue[800],
      color: 'white'
    },
    '&:hover': {
      backgroundColor: colors.blue[800],
      opacity: '.8',
      color: 'white'
    }
  },
  title: {
    color: colors.blue[800],
    fontWeight: 'bold',
    fontSize: '30px',
    textAlign: 'start'
  }
}
