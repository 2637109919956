import { useSnackbar, useWS } from '../hooks'
import { useCallback } from 'react'

import { WSContext } from '../context'

export const WebsocketWrapper = ({ children }) => {
  const { setSnackbarMessage } = useSnackbar()

  const websockets = useWS({
    onError: useCallback(
      ({ message }) => {
        setSnackbarMessage(message)
      },
      [setSnackbarMessage]
    )
  })

  return <WSContext.Provider value={websockets}>{children}</WSContext.Provider>
}
