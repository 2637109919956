import { Link } from '@mui/material'
import { colors } from '../../styles/colors'

const ItemList = ({ children }) => (
  <ul style={{ padding: '0', margin: '0' }}>{children}</ul>
)

const Item = ({ id, label }) => (
  <li style={{ listStyle: 'none' }}>
    <Link
      component="button"
      underline="none"
      sx={{
        textAlign: 'left',
        fontSize: '1rem',
        color: colors.blue[800],
        fontWeight: 'bold',
        marginBottom: '0.2rem'
      }}
      onClick={() => {
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }}
    >
      {label}
    </Link>
  </li>
)

export { ItemList, Item }
