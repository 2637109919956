import { useContext } from 'react'
import { LoadingContext } from '../context'

const useLoading = () => {
  const context = useContext(LoadingContext)
  if (context === undefined) {
    throw new Error('useWSContext must be used within a LoadingContextProvider')
  }
  return context
}

export { useLoading }
