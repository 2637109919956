import { Typography } from '@mui/material'
import { colors } from '../../styles/colors'

const Tag = ({ children }) => {
  return (
    <Typography
      sx={{
        mt: '.5rem',
        fontSize: '1rem',
        fontWeight: '700',
        color: '#ffffff',
        padding: '.25rem .5rem',
        background: colors.blue[500],
        borderRadius: '3px',
        flexShrink: 0
      }}
    >
      {children}
    </Typography>
  )
}

export { Tag }
