import { useWSContext } from './useWSContext'
import { useEffect } from 'react'

const useWSMessageListener = listener => {
  const websockets = useWSContext()
  useEffect(() => {
    websockets.addMessageListener(listener)

    return () => {
      websockets.removeMessageListener(listener)
    }
  }, [listener, websockets])
}

export { useWSMessageListener }
