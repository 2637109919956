import { useContext } from 'react'
import { WSContext } from '../context'

const useWSContext = () => {
  const context = useContext(WSContext)
  if (context === undefined) {
    throw new Error('useWSContext must be used within a WSProvider')
  }
  return context
}

export { useWSContext }
