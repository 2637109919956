import { setDefaults, fromAddress } from 'react-geocode'
import { REACT_APP_GOOGLE_MAPS_API_KEY } from '../constants'

const convertAddressToLocation = async address => {
  setDefaults({
    key: REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'en'
  })

  const data = await fromAddress(address)
  if (data.status === 'OK') {
    const [addressData] = data.results
    return addressData
  }
}

export { convertAddressToLocation }
