import { useWSContext } from './useWSContext'
import { useEffect } from 'react'

const useWSStatusChangeListener = listener => {
  const websockets = useWSContext()

  useEffect(() => {
    websockets.addStatusChangeListener(listener)

    return () => {
      websockets.removeStatusChangeListener(listener)
    }
  }, [listener, websockets])
}

export { useWSStatusChangeListener }
