import { Box, Typography } from '@mui/material'
import React from 'react'
import { Card } from '../Card'

import { useWSMessageListener } from '../../hooks'
import { CardSkeleton } from '../CardSkeleton'

function extractTitleAndAnswer(input) {
  const titleRegex = /title:\s*([^\n#]+)/
  const answerRegex = /answer:\s*(.*)/

  const titleMatch = input.match(titleRegex)
  const answerMatch = input.match(answerRegex)

  let title = titleMatch ? titleMatch[1].trim() : null
  const answer = answerMatch ? answerMatch[1].trim() : null

  title = title.replace(/answer:[\s\S]*/, '').trim()
  return { title, answer }
}

export function TravelTips({
  setTravelTip,
  travelTip,
  setTravelTipTitle,
  travelTipTitle
}) {
  useWSMessageListener(data => {
    if (data.type === 'travel-tips') {
      const { title, answer } = extractTitleAndAnswer(data.data.response)
      setTravelTip(answer)
      setTravelTipTitle(title)
    }
  })
  return (
    <>
      {travelTip ? (
        <Card title="Travel tips">
          <Box sx={styles.container}>
            <Typography style={styles.title} variant="h5">
              {travelTipTitle}
            </Typography>
            <Typography variant="body1">{travelTip}</Typography>
          </Box>
        </Card>
      ) : (
        <Card title="Travel tips">
          <CardSkeleton />
        </Card>
      )}
    </>
  )
}

const styles = {
  container: {
    padding: '1.625rem',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bold'
  }
}
