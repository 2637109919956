import { useContext } from 'react'
import { NavigationContext, NAVIGATION_IDS } from '../context'

const useNavigation = () => {
  const context = useContext(NavigationContext)
  if (context === undefined) {
    throw new Error(
      'useNavigation must be used within a NavigationContextProvider'
    )
  }
  return context
}

export { useNavigation, NAVIGATION_IDS }
