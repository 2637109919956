import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { THEME } from './theme'
import { LoadingProvider, NavigationProvider, AuthProvider } from './context'
import { updateTheme } from './utils'
import { colors } from './styles/colors'

updateTheme()

const theme = createTheme(THEME(colors))

const LOADING_SECTIONS = [
  'flight-status',
  'open-chat-questions',
  'airport-info'
]

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <AuthProvider>
        <LoadingProvider initialSections={LOADING_SECTIONS}>
          <NavigationProvider>
            <App />
          </NavigationProvider>
        </LoadingProvider>
      </AuthProvider>
    </React.StrictMode>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
