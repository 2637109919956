export const firebaseConfig = {
  apiKey: 'AIzaSyBcTnOmyMoXB2an4elnKobvc1CBAxao3jI',
  authDomain: 'ai-travel-agent-388410.firebaseapp.com',
  projectId: 'ai-travel-agent-388410',
  storageBucket: 'ai-travel-agent-388410.appspot.com',
  messagingSenderId: '296608284663',
  appId: '1:296608284663:web:b86ae4f97541a5696138f2'
}

export const REACT_APP_GOOGLE_MAPS_API_KEY =
  'AIzaSyCjg6r_i4kHJXgHqgKqnrwAvqnaHMJ_w1c'
