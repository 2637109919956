import { AuthContext } from '../context'
import { Box, Link, Typography } from '@mui/material'
import { colors } from '../styles/colors'
import { useContext } from 'react'

const AI_TRAVEL_AGENT_REPO_URL = 'https://github.com/nearform/ai-travel-agent'

export function Credits() {
  const { logOut, currentUser } = useContext(AuthContext)

  const styles = {
    container: {
      margin: 'auto 1rem 1rem 1rem',
      padding: '0.25rem'
    },
    text: {
      color: colors.blue[800],
      float: 'right',
      fontSize: '14px'
    },
    link: {
      color: colors.blue[800],
      fontWeight: 'bold',
      textDecoration: 'underline'
    },
    logoutLink: {
      cursor: 'pointer'
    }
  }

  const firstName = currentUser?.displayName?.split(' ')[0]

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.text}>
        Made with ♥ by{' '}
        <Link target="_blank" sx={styles.link} href={AI_TRAVEL_AGENT_REPO_URL}>
          NearForm
        </Link>
        {currentUser && (
          <>
            {` (for ${firstName} `}
            <Link
              target="_blank"
              sx={{ ...styles.link, ...styles.logoutLink }}
              onClick={logOut}
            >
              Sign out
            </Link>
            {')'}
          </>
        )}
      </Typography>
    </Box>
  )
}
