import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import {
  useWSMessageListener,
  useNavigation,
  NAVIGATION_IDS,
  useLoading
} from '../../hooks'
import { Card } from '../Card'

export function Recomendations() {
  const [recomendations, setRecomendations] = useState([])
  const { loadingState } = useLoading()
  const { showNavItem } = useNavigation()

  useEffect(() => {
    if (loadingState['flight-status']) {
      setRecomendations([])
    }
  }, [loadingState])

  useEffect(() => {
    if (recomendations.length > 0) {
      showNavItem(NAVIGATION_IDS.ACTIVITIES, 'More infos...')
    }
  }, [recomendations, showNavItem])

  useWSMessageListener(data => {
    if (data.type === 'open-chat') {
      setRecomendations(currentAnswers => [...currentAnswers, data.data])
    }
  })

  if (!recomendations.length) {
    return null
  }

  return (
    <>
      {recomendations.map((recomendation, index) => (
        <Card
          title={recomendation.question}
          key={recomendation.question}
          id={index === 0 ? NAVIGATION_IDS.ACTIVITIES : undefined}
        >
          <Box sx={styles.container}>
            <Typography variant="body1">{recomendation.answer}</Typography>
          </Box>
        </Card>
      ))}
    </>
  )
}

const styles = {
  container: {
    padding: '1.625rem',
    display: 'flex',
    flexDirection: 'column'
  }
}
