import { useContext, useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useGeolocation } from '@uidotdev/usehooks'

import { useLoading, useNavigation, NAVIGATION_IDS } from '../hooks'
import { MapRoute } from '../components/Map/MapRoute'
import { FlightDataContext } from '../context/FlightDataContext'

const CARD_NAME = 'Getting to the airport'

export function GettingToAirport() {
  const [userWantHelp, setUserWantHelp] = useState(null)
  const { error, data: flightData } = useContext(FlightDataContext)

  const { loadingState } = useLoading()
  const { showNavItem, hideNavItem } = useNavigation()
  const currentLocation = useGeolocation()

  useEffect(() => {
    if (flightData) {
      setUserWantHelp(null)
      showNavItem(
        NAVIGATION_IDS.GETTING_TO_AIRPORT,
        'Need help going to the airport?'
      )
    }
  }, [flightData, showNavItem])

  if (loadingState['flight-status'] !== false || flightData === null || error) {
    return null
  }

  function HelpQuestionaryComponent() {
    return (
      <Box as="section" sx={{ mb: '2.5rem' }}>
        <Typography variant="h3" id={NAVIGATION_IDS.GETTING_TO_AIRPORT}>
          Need help going to the airport?
        </Typography>
        <Box sx={{ px: '1.65rem' }}>
          <Button
            variant="prompt"
            onClick={async () => await handleUserWantsHelpClick()}
          >
            Yes
          </Button>
          <Button
            variant="prompt"
            onClick={() => {
              setUserWantHelp(false)
              hideNavItem(NAVIGATION_IDS.GETTING_TO_AIRPORT)
            }}
          >
            No, thank you
          </Button>
        </Box>
      </Box>
    )
  }

  async function handleUserWantsHelpClick() {
    setUserWantHelp(true)
    showNavItem(NAVIGATION_IDS.GETTING_TO_AIRPORT, CARD_NAME)
  }

  if (userWantHelp === true) {
    return (
      <MapRoute
        latestLeaveTime={flightData.latestLeaveTime}
        cardTitle={CARD_NAME}
        cardId={NAVIGATION_IDS.GETTING_TO_AIRPORT}
        destinationData={flightData?.airport}
        departureLocation={currentLocation}
      />
    )
  } else if (userWantHelp === false) {
    return null
  } else {
    return <HelpQuestionaryComponent />
  }
}
