import { Box } from '@mui/material'
import { Skeleton } from '../components/Skeleton'

export function CardSkeleton() {
  return (
    <Box sx={{ padding: '1.625rem' }}>
      <Skeleton />
    </Box>
  )
}
