export const THEME = colors => {
  return {
    palette: {
      primary: {
        main: colors.blue[500]
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'Montserrat',
            padding: '0.5rem 2.3rem'
          }
        },
        variants: [
          {
            props: { variant: 'secondary' },
            style: {
              padding: '.2rem .4rem',
              fontWeight: '600',
              background: colors.purple[500],
              color: colors.purple[100],
              borderRadius: '2px',
              '&:hover': {
                backgroundColor: colors.purple[500],
                opacity: '.8'
              }
            }
          },
          {
            props: { variant: 'prompt' },
            style: {
              display: 'flex',
              justifyContent: 'flex-start',
              padding: '0.5rem 1.3rem',
              fontSize: '.875rem',
              color: '#ffffff',
              width: '100%',
              background: colors.blue[500],
              marginBottom: '10px',
              '&:hover': {
                backgroundColor: colors.blue[500],
                opacity: '.8'
              },
              '& + button': {
                mt: '.5rem'
              }
            }
          },
          {
            props: { variant: 'close' },
            style: {
              width: '35px',
              height: '35px',
              minWidth: '35px',
              fontWeight: '600',
              background: colors.purple[500],
              color: colors.purple[100],
              borderRadius: '50%',
              padding: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: colors.purple[500],
                opacity: '.8'
              }
            }
          },
          {
            props: { variant: 'contained' },
            style: {
              color: '#ffffff'
            }
          }
        ]
      }
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      button: {
        textTransform: 'none'
      },
      h3: {
        color: colors.blue[800],
        fontSize: '1rem',
        paddingLeft: '1.625rem',
        marginBottom: '1rem'
      },
      h5: {
        fontFamily: 'Montserrat'
      }
    },
    shadows: [
      'none',
      '0px 4px 8px rgba(243, 246, 250, 0.6), 0px 12px 12px rgba(62, 61, 81, 0.25)'
    ],
    shape: {
      borderRadius: 8
    }
  }
}
