import { useWSContext } from './useWSContext'

const useWSSender = () => {
  const websockets = useWSContext()

  const send = (message, callback) => {
    if (!websockets) return

    websockets.ws.send(JSON.stringify(message))
    if (callback) {
      callback()
    }
  }

  return { send }
}

export { useWSSender }
