import React from 'react'
import { Skeleton as MUISkeleton } from '@mui/material'

const Skeleton = ({ numberOfLines = 5 }) => (
  <>
    {[...Array(numberOfLines)].map((_, i) => (
      <MUISkeleton key={i} animation="wave" />
    ))}
  </>
)

export { Skeleton }
