import { validTheme } from './index'
import { colors } from '../styles/colors'

const updateTheme = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const hue = urlParams.get('theme')

  if (validTheme(hue)) {
    colors.blue[500] = `hsl(${hue},41%,43%)`
    colors.blue[800] = `hsl(${hue},90%,10%)`
    colors.red[600] = `hsl(${hue},41%,43%)`
    colors.red[300] = `hsl(${hue},70%,60%)`
    colors.purple[100] = `hsl(${hue},70%,10%)`
    colors.purple[500] = `hsl(${hue},40%,90%)`
    colors.purple[300] = `hsl(${hue},40%,90%)`
  }
}

export { updateTheme }
