import React, { createContext, useCallback, useMemo, useState } from 'react'

const LoadingContext = createContext()

const LoadingProvider = ({ children, initialSections }) => {
  const [loadingState, setLoadingState] = useState(
    initialSections.reduce((obj, section) => ({ ...obj, [section]: null }), {})
  )

  const startLoading = useCallback(() => {
    setLoadingState(prevState =>
      Object.keys(prevState).reduce(
        (obj, section) => ({ ...obj, [section]: true }),
        {}
      )
    )
  }, [])

  const readyToLoad = useMemo(() => {
    return Object.values(loadingState).every(value => value === null)
  }, [loadingState])

  const startLoadingSection = useCallback(section => {
    setLoadingState(prevState => ({ ...prevState, [section]: true }))
  }, [])

  const loadingSectionDone = useCallback(section => {
    setLoadingState(prevState => ({ ...prevState, [section]: false }))
  }, [])

  const loadingDone = useMemo(() => {
    return Object.values(loadingState).every(value => value === false)
  }, [loadingState])

  const resetLoading = useCallback(() => {
    setLoadingState(prevState => ({
      ...prevState,
      ...initialSections.reduce(
        (obj, section) => ({ ...obj, [section]: null }),
        {}
      )
    }))
  }, [initialSections])

  const value = useMemo(() => {
    return {
      loadingState,
      startLoading,
      startLoadingSection,
      loadingSectionDone,
      resetLoading,
      loadingDone,
      readyToLoad
    }
  }, [
    loadingState,
    startLoading,
    startLoadingSection,
    loadingSectionDone,
    resetLoading,
    loadingDone,
    readyToLoad
  ])

  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  )
}

export { LoadingContext, LoadingProvider }
