import { createContext, useCallback, useState } from 'react'
import { useWSMessageListener } from '../hooks'
import { formatDate } from '../utils'

const INITIAL_STATE = {
  error: null,
  data: null
}

const HOURS_TO_LEAVE_BEFORE_FLIGHT = 4

const FlightDataContext = createContext()

const FlightDataProvider = ({ children }) => {
  const [flightData, setFlightData] = useState(INITIAL_STATE)

  const updateState = (key, data) =>
    setFlightData({ ...INITIAL_STATE, [key]: data })

  const getLatestLeaveTime = useCallback(departureDateTime => {
    const datetime = new Date(departureDateTime)
    const calculateLatestLeaveTime = datetime.setHours(
      datetime.getHours() - HOURS_TO_LEAVE_BEFORE_FLIGHT
    )
    return formatDate(calculateLatestLeaveTime)
  }, [])

  useWSMessageListener(data => {
    try {
      if (data.type === 'flight-status') {
        const { data: flightInfo } = data
        const flightStatus = JSON.parse(flightInfo)

        if (flightStatus.error) {
          updateState('error', flightStatus.error)
          return
        }

        const latestLeaveTime = getLatestLeaveTime(
          flightStatus.departure.actualTimeLocal
            ? flightStatus.departure.actualTimeLocal
            : flightStatus.departure.scheduledTimeLocal
        )
        updateState('data', {
          ...flightStatus.departure,
          latestLeaveTime,
          arrival: flightStatus.arrival
        })
      }
    } catch (error) {
      updateState('error', error)
    }
  })

  return (
    <FlightDataContext.Provider value={flightData}>
      {children}
    </FlightDataContext.Provider>
  )
}

export { FlightDataContext, FlightDataProvider }
