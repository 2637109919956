import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLoading } from './useLoading'

const MAX_RETRY_COUNT = 5
const RETRY_TIMEOUT = 2000

const useWS = ({ onError }) => {
  const [retryCount, setRetryCount] = useState(0)
  const [ws, setWs] = useState(null)
  const messageListeners = useRef([])
  const statusChangeListeners = useRef([])
  const { resetLoading } = useLoading()
  const notifyStatusChange = status => {
    for (const listener of statusChangeListeners.current) {
      listener(status)
    }
  }

  useEffect(() => {
    const wsConnection = new WebSocket(
      process.env.REACT_APP_BACKEND_WS_URL || `wss://${window.location.host}/ws`
    )
    setWs(wsConnection)
  }, [setWs])

  useEffect(() => {
    if (!ws) return

    ws.onopen = () => {
      notifyStatusChange('OPEN')
    }

    ws.onerror = error => {
      console.log(`WebSocket error: ${error}`)
    }

    ws.onmessage = event => {
      const data = JSON.parse(event.data)

      for (const listener of messageListeners.current) {
        listener(data)
      }
    }

    ws.onclose = event => {
      notifyStatusChange('CLOSED')
      console.log('WebSocket connection closed')
      if (retryCount < MAX_RETRY_COUNT) {
        console.log(
          `Attempting to reconnect (${retryCount + 1}/${MAX_RETRY_COUNT})...`
        )
        setTimeout(() => {
          setWs(null)
          setRetryCount(retryCount + 1)
        }, RETRY_TIMEOUT)
      } else {
        resetLoading()
        onError({ message: 'Server connection lost' })
        console.log('Failed to reconnect after multiple attempts')
      }
    }

    return () => {
      if (ws.readyState === 1) {
        ws.close()
      }
    }
  }, [ws, messageListeners, retryCount, onError, resetLoading])

  const addMessageListener = useCallback(listener => {
    messageListeners.current = [...messageListeners.current, listener]
  }, [])

  const removeMessageListener = useCallback(listener => {
    messageListeners.current = messageListeners.current.filter(
      l => l !== listener
    )
  }, [])

  const addStatusChangeListener = useCallback(listener => {
    statusChangeListeners.current = [...statusChangeListeners.current, listener]
  }, [])

  const removeStatusChangeListener = useCallback(listener => {
    statusChangeListeners.current = statusChangeListeners.current.filter(
      l => l !== listener
    )
  }, [])

  return useMemo(
    () => ({
      ws,
      addMessageListener,
      removeMessageListener,
      addStatusChangeListener,
      removeStatusChangeListener
    }),
    [
      addMessageListener,
      removeMessageListener,
      addStatusChangeListener,
      removeStatusChangeListener,
      ws
    ]
  )
}

export { useWS }
