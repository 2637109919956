function formatDate(dateString) {
  const options = {
    hour: 'numeric',
    minute: '2-digit'
  }
  if (!dateString) return ''
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString))
}

export { formatDate }
