import { Box } from '@mui/material'

export function LayoutContainer({ children }) {
  return (
    <Box
      as="header"
      sx={{
        display: 'flex',
        maxWidth: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          width: '550px'
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
