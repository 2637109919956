import { Typography, Box } from '@mui/material'
import React, { useEffect, useState, useMemo } from 'react'
import {
  useLoading,
  useWSMessageListener,
  useNavigation,
  NAVIGATION_IDS
} from '../hooks'
import { Card } from '../components/Card'
import { colors } from '../styles/colors'
import { CardSkeleton } from '../components/CardSkeleton'

const CARD_TITLE = 'At the airport'

export function AirportInfo() {
  const [data, setData] = useState(null)
  const [city, setCity] = useState(null)
  const [error, setError] = useState(null)
  const { loadingState, loadingSectionDone, readyToLoad } = useLoading()
  const { showNavItem } = useNavigation()

  const isLoading = loadingState['airport-info']
  const isDataLoaded = useMemo(() => data && !isLoading, [data, isLoading])

  useEffect(() => {
    if (readyToLoad) {
      setData(null)
    }
  }, [readyToLoad])

  useEffect(() => {
    if (isDataLoaded) {
      showNavItem(NAVIGATION_IDS.AIRPORT_INFO, CARD_TITLE)
    }
  }, [isDataLoaded, showNavItem])

  useWSMessageListener(data => {
    try {
      if (data.type === 'airport-info') {
        setData(JSON.parse(data.data))
        loadingSectionDone('airport-info')
      }
      if (data.type === 'flight-status') {
        const flightStatus = JSON.parse(data.data)

        if (!flightStatus || flightStatus.error) {
          setCity(null)
        } else {
          const {
            airport: { municipalityName }
          } = flightStatus.departure

          setCity(municipalityName)
        }
      }
    } catch (error) {
      setError(error)
    }
  })

  if (loadingState['airport-info'] === null || error) {
    return null
  }

  return (
    <Card title={CARD_TITLE} id={NAVIGATION_IDS.AIRPORT_INFO}>
      {isLoading ? <CardSkeleton /> : null}
      {isDataLoaded ? (
        <Box sx={styles.container}>
          <Typography
            sx={{
              color: colors.blue[800],
              fontSize: '0.875rem',
              fontWeight: '400',
              mb: '1.25rem'
            }}
          >
            Here are a few things to do near to the {city} Airport.
          </Typography>
          {data &&
            data.items.map(({ item, explanation }, index) => (
              <Box
                key={index}
                sx={{
                  '& + div': {
                    mt: '1.25rem'
                  }
                }}
              >
                <Typography
                  sx={{
                    color: colors.blue[800],
                    fontSize: '1.25rem',
                    fontWeight: '700'
                  }}
                >
                  {item}
                </Typography>
                <Typography
                  sx={{
                    color: colors.blue[800],
                    fontSize: '1rem',
                    fontWeight: '400'
                  }}
                >
                  {explanation}
                </Typography>
              </Box>
            ))}
        </Box>
      ) : null}
    </Card>
  )
}

const styles = {
  container: {
    padding: '1.625rem',
    display: 'flex',
    flexDirection: 'column'
  }
}
