import { Box, Typography } from '@mui/material'
import { Card } from '../../components/Card'
import { Map } from './Map'
import { colors } from '../../styles/colors'
import { formatTravelTime } from '../../utils'
import { useState } from 'react'

const MapRoute = ({
  departureLocation,
  destinationData,
  latestLeaveTime,
  cardTitle,
  cardId,
  children
}) => {
  const [routeData, setRouteData] = useState(null)

  return (
    <Card title={cardTitle} id={cardId || ''}>
      <Box sx={styles.infoContainer}>
        {children}
        {routeData?.fastestRoute && (
          <Box sx={{ display: 'flex', gap: '.25rem' }}>
            <Typography
              sx={{
                color: colors.red[600],
                fontSize: '0.75rem',
                fontWeight: '700'
              }}
            >
              Leave at {latestLeaveTime}
            </Typography>
            <Typography
              sx={{
                color: colors.red[600],
                fontSize: '0.75rem',
                fontWeight: '400'
              }}
            >
              (at the latest)
            </Typography>
          </Box>
        )}
        {destinationData && (
          <>
            <Box
              sx={{
                display: 'flex',
                gap: '.25rem',
                mt: '.5rem',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: colors.blue[800],
                    fontSize: '1.25rem',
                    fontWeight: '700'
                  }}
                >
                  {departureLocation.error
                    ? 'Ensure geolocation is turned on'
                    : routeData?.fastestRoute
                    ? `Fastest way is by driving via ${routeData?.fastestRoute}`
                    : 'No route available'}
                  .
                </Typography>
                {routeData?.includeFerry && (
                  <Typography
                    sx={{
                      color: colors.blue[800]
                    }}
                  >
                    This route includes journey(s) by Ferry &#128674;.
                  </Typography>
                )}
              </Box>
              <Typography
                sx={{
                  color: colors.red[600],
                  fontSize: '1.25rem',
                  fontWeight: '700'
                }}
              >
                {routeData?.duration && formatTravelTime(routeData?.duration)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {departureLocation.error || departureLocation.loading || destinationData?.loading ? (
        <Box sx={styles.infoContainer}></Box>
      ) : (
        <Box sx={styles.mapContainer}>
          <Map
            location={{
              lat: departureLocation.latitude,
              lng: departureLocation.longitude
            }}
            destination={destinationData}
            setDestinationDirectionData={setRouteData}
          />
        </Box>
      )}
    </Card>
  )
}

const styles = {
  infoContainer: {
    padding: '1.625rem 1.625rem 0rem 1.625rem'
  },
  mapContainer: {
    '> div': {
      mt: '2rem',
      width: '100% !important'
    },
    display: 'flex',
    flexDirection: 'column'
  }
}

export { MapRoute }
