import { Button, CircularProgress } from '@mui/material'

const QuestionButton = ({ onClick, isLoading, children }) => {
  return (
    <Button variant='prompt' onClick={onClick}>
      {isLoading && (
        <CircularProgress
          sx={{
            color: theme => theme.palette.grey[200],
            marginRight: '1rem'
          }}
          size={20}
          thickness={4}
        />
      )}
      {children}
    </Button>
  )
}

export { QuestionButton }
