import React from 'react'
import { Button, CircularProgress, TextField, Box } from '@mui/material'
import { colors } from '../styles/colors'
import CloseIcon from '@mui/icons-material/Close'

const Search = ({ value, error, isLoading, onChange, onSubmit, placeholder }) => {

  const handleChange = e => onChange(e.target.value)

  const handleClickOnSubmit = e => {
    e.preventDefault()
    onSubmit()
  }

  const clearSearch = () => {
    onChange('')
  }

  return (
    <Box sx={styles.container} component="form" onSubmit={handleClickOnSubmit}>
      <Box
        sx={{ ...styles.search, border: `1px solid ${colors.blue[800]}` }}
        gap={2}
      >
        <TextField
          error={error}
          fullWidth
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          variant="outlined"
          inputProps={{
            sx: {
              fontSize: '0.85rem',
              backgroundColor: '#ffffff',
              '&::placeholder': {
                color: colors.blue[800],
                opacity: 1 // otherwise firefox shows a lighter color
              }
            }
          }}
          sx={{
            fieldset: {
              border: '0px'
            }
          }}
        />
        {value && (
          <Button variant="close" onClick={() => clearSearch()}>
            <CloseIcon htmlColor={colors.blue[800]} />
          </Button>
        )}
      </Box>
      <Button
        type="submit"
        variant="contained"
        disabled={isLoading}
        sx={{
          '&': {
            backgroundColor: colors.blue[800]
          },
          '&:hover': {
            backgroundColor: colors.blue[800],
            opacity: '.8'
          }
        }}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Search'}
      </Button>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    '@media (max-width:600px)': {
      ml: '1rem'
    },
    '> button': {
      fontSize: '0.75rem',
      p: '0rem 0rem'
    }
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${colors.blue[800]}`,
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    marginRight: '16px',
    width: '100%',
    button: {
      margin: '0px 10px'
    }
  }
}

export { Search }
