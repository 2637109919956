import { createContext, useEffect, useMemo, useState } from 'react'
import { signOut, signInWithPopup } from 'firebase/auth'
import { auth, googleProvider, microsoftProvider } from '../firebase'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState()
  const [authLoading, setAuthLoading] = useState()

  const signInWithGoogle = async () => {
    try {
      setAuthLoading(true)
      await signInWithPopup(auth, googleProvider)
    } catch (err) {
      console.error(err)
    } finally {
      setAuthLoading(false)
    }
  }
  const signInWithMicrosoft = async () => {
    try {
      setAuthLoading(true)
      await signInWithPopup(auth, microsoftProvider)
    } catch (err) {
      console.error(err)
    } finally {
      setAuthLoading(false)
    }
  }
  const logOut = async () => {
    try {
      await signOut(auth)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setAuthLoading(false)
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(null)
      }
    })
  }, [])

  const value = useMemo(
    () => ({
      currentUser,
      authLoading,
      signInWithGoogle,
      signInWithMicrosoft,
      logOut
    }),
    [currentUser, authLoading]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
