import React, { useCallback, useState } from 'react'
import { Alert, Snackbar as MUISnackbar } from '@mui/material'
import { createPortal } from 'react-dom'

const useSnackbar = () => {
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null)
  }

  const SnackBar = useCallback(
    () => (
      <MUISnackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </MUISnackbar>
    ),
    [snackbarMessage]
  )

  const SnackBarPortal = snackbarMessage
    ? createPortal(<SnackBar />, document.getElementById('snackbar'))
    : null

  return { setSnackbarMessage, SnackBarPortal }
}

export { useSnackbar }
