export const colors = {
  blue: {
    500: '#2E66E3',
    800: '#11013F'
  },
  gray: {
    100: '#F3F6FA',
    200: '#E5E9EE',
    300: '#CED6E0',
    400: '#BAC1C9',
    600: '#656565'
  },
  red: {
    600: '#9A4179',
    300: '#FF70A3'
  },
  purple: {
    100: '#130048',
    500: '#DDD7EF'
  }
}
