import React from 'react'
import { Box, Container } from '@mui/material'
import { useSnackbar } from './hooks'
import { AuthWrapper } from './auth/AuthWrapper'
import { WebsocketWrapper } from './auth/WebsocketWrapper'
import { Header, Navigation } from './components'
import {
  Departure,
  GettingToAirport,
  AirportInfo,
  Destination,
  Credits,
  OpenChatSection
} from './sections'
import { FlightDataProvider } from './context/FlightDataContext'

const App = () => {
  const { SnackBarPortal } = useSnackbar()

  const contentStyle = {
    transition: 'margin 0.2s',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  }

  const resultStyle = {
    position: 'relative'
  }

  return (
    <AuthWrapper>
      <WebsocketWrapper>
        <Box sx={contentStyle}>
          {SnackBarPortal}
          <Header />
          <Box sx={resultStyle}>
            <Navigation />
            <Box>
              <Container maxWidth="sm">
                <Box my={4}>
                  <FlightDataProvider>
                    <GettingToAirport />
                    <AirportInfo />
                    <Departure />
                    <Destination />
                    <OpenChatSection />
                  </FlightDataProvider>
                </Box>
              </Container>
            </Box>
          </Box>
          <Credits />
        </Box>
      </WebsocketWrapper>
    </AuthWrapper>
  )
}

export default App
